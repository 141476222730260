import { Box, 
  Container, 
  Grid, 
  Paper,  
  List,
  ListItem,
  ListItemIcon,
  ListItemText } from "@material-ui/core";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory, useLocation } from "react-router-dom";
import { useEMs }  from "ems/useEMs";
import { useSortedEMs } from "ems/useSortedEMs";
import { CountCard } from "../../components/CountCard";
import useBudget from "../../customers/useBudget";
import useCustomers from "../../customers/useCustomers";
import { useDashboardPeriod } from "../useDashboardPeriod";
import { EMsTable } from "./EMsTable";
import { EMUtilizationScatterChartCard } from "./EMUtilizationScatterChartCard";
import { EMCapacityScatterChartCard } from "./EMCapacityScatterChartCard";

export function EMsDashboardOverviewPage() {
  const period = useDashboardPeriod();
  const ems = useEMs(period.year, period.month);
  const sortedEms = useSortedEMs(period.year, period.month);
  const location = useLocation();
  const history = useHistory();
  const customers = useCustomers(period.year, period.month);
  const budget = useBudget(period.year, period.month);

  return (
    <Box>
      <Container maxWidth="xl">
        <DashboardBreadcrumbs docs="/dashboards/ems.html"/>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={4}>
            <EMUtilizationScatterChartCard
              year={period.year}
              month={period.month}
            />
          </Grid>

          <Grid item xs={4}>
            <EMCapacityScatterChartCard
              year={period.year}
              month={period.month}
            />
          </Grid>

          <Grid item xs={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={6}>
                <CountCard title="EMs" count={Object.values(ems).length} />
              </Grid>
              <Grid item xs={6}>
                <CountCard
                  title="Customers"
                  count={customers.getAll().length}
                />
              </Grid>
              <Grid item xs={6}>
                <CountCard title="EM Hours" count={budget.emHours} />
              </Grid>
              <Grid item xs={6}>
                <CountCard
                  title="Budget"
                  count={budget.opsHours + budget.teamHours}
                />
              </Grid>
              <Grid item xs={6}>
              <List>
                <ListItem
                  button
                  onClick={() => history.push(`${location.pathname}/delta`)}
                >
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delta trend" />
                </ListItem>
              </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Paper>
        <EMsTable
          year={period.year}
          month={period.month}
          ems={sortedEms}
          linkPrefix="/dashboards/ems"
        />
      </Paper>
    </Box>
  );
}
