import {
  Box,
  Checkbox,
  Chip,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from "@material-ui/core";
import useAllCustomers from "customers/useAllCustomers";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { DocsDrawer } from "docs/DocsDrawer";
import { useAllEngineers } from "engineers/useAllEngineers";
import {
  AggregatedBudget,
  Team,
  TeamSnapshot,
  TeamType
} from "msd-capacity-planning-model";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useRegions from "regions/useRegions";
import useSortedRegions from "regions/useSortedRegions";
import useProductTypes from "teams/useProductTypes";
import useTeams  from "teams/useSortedTeams";
import { useTeamTypes } from "teams/useTeamTypes";
import useLinkStyles from "../../utils/useLinkStyle";
import { mapValueToColor } from "utils/colorGenerator";

class TeamDeltaSnapshot {
  constructor(public snapshot: TeamSnapshot) { }

  getDate(): Date {
    return this.snapshot.getDate();
  }

  getBudget(): AggregatedBudget {
    return this.snapshot?.getBudget();
  }

  getPlannedHours(): number {
    return this.getBudget()?.getPlannedHours() || 0;
  }

  getCapacity(now: Date): number {
    return this.getDate().getTime() < now.getTime()
      ? this.snapshot?.getHarvestHours().total
      : this.snapshot?.getUtilizationHours() || 0;
  }

  getCapacityLabel(now: Date): string {
    return this.getDate().getTime() < now.getTime()
      ? "Harvest hours"
      : "Planned capacity";
  }

  getDelta(now: Date): number {
    return this.getCapacity(now) - this.getPlannedHours();
  }

  getDeltaPercent(now: Date): number {
    const capacity = this.getCapacity(now);
    const planned = this.getPlannedHours() || 0;
    let deltaPercent = (planned / capacity) * 100;
    deltaPercent = isFinite(deltaPercent) ? deltaPercent : 0;
    return deltaPercent;
  }

  getTimeoffHours(): number {
    return this.snapshot?.getTimeOfffHours() || 0;
  }

  countEngineers(): number {
    return this.snapshot?.engineers?.length || 0;
  }

  countCustomers(): number {
    return this.snapshot?.customers?.length || 0;
  }

  countCustomersOffboarded(): number {
    return this.snapshot.countCustomerOffboardings();
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      textAlign: "right",
    },
  })
);

function createDates(year: number, month: number): Date[] {
  return [
    new Date(year, month - 3),
    new Date(year, month - 2),
    new Date(year, month - 1),
    new Date(year, month),
    new Date(year, month + 1),
    new Date(year, month + 2),
    new Date(year, month + 3),
  ];
}

export function TeamsDeltaDashboardPage({
  teams = {},
}: {
  teams: { [teamId: string]: Team };
}) {
  const { year, month } = useDashboardPeriod();
  const now = new Date();
  const [nowDate] = useState(new Date(year, month));
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles();
  const linkStyles = useLinkStyles();

  const sortedTeams = useTeams(year, month);
  const [filteredTeams, setFilteredTeams] = useState([] as Team[]);
  const engineers = useAllEngineers();
  const customers = useAllCustomers();
  const regions = useRegions();

  const sortedRegions = useSortedRegions();
  const [regionFilter, setRegionFilter] = useState(
    undefined as string | undefined
  );
  const teamTypes = useTeamTypes();
  const [teamTypeFilter, setTeamTypeFilter] = useState(
    undefined as TeamType | undefined
  );
  const productTypes = useProductTypes();
  const [productTypeFilter, setProductTypeFilter] = useState(
    undefined as string | undefined
  );

  const [dates, setDates] = useState([] as Date[]);
  const [snapshots, setSnapshots] = useState(
    {} as { [date: string]: { [teamId: string]: TeamDeltaSnapshot } }
  );

  const [showEngineers, setShowEngineers] = useState(false);
  const [showCustomers, setShowCustomers] = useState(false);
  const [showOffboardings, setShowOffboardings] = useState(false);
  const [showTimeOff, setShowTimeOff] = useState(false);

  useEffect(() => {
    // calculate dates
    const dates = createDates(year, month);
    setDates(dates);

    // create snapshots
    const newSnapshots = dates.reduce((map, date) => {
      map[date.toISOString().substring(0, 7)] = sortedTeams.reduce(
        (teamMap, team) => {
          teamMap[team.id] = new TeamDeltaSnapshot(
            new TeamSnapshot(
              date.getFullYear(),
              date.getMonth(),
              team,
              Object.values(engineers),
              Object.values(customers),
              regions
            )
          );
          return teamMap;
        },
        {} as { [teamId: string]: TeamDeltaSnapshot }
      );
      return map;
    }, {} as { [date: string]: { [teamId: string]: TeamDeltaSnapshot } });
    setSnapshots(newSnapshots);
  }, [year, month, engineers, customers, regions, sortedTeams]);

  useEffect(() => {
    const newFiltedTeams = sortedTeams.filter((team) => {
      if (regionFilter && team.region !== regionFilter) return false;
      if (teamTypeFilter && !team.hasTeamType(teamTypeFilter)) return false;
      if (productTypeFilter && !team.hasProductType(productTypeFilter))
        return false;
      return true;
    });
    setFilteredTeams(newFiltedTeams);
  }, [
    nowDate,
    dates,
    snapshots,
    sortedTeams,
    regionFilter,
    teamTypeFilter,
    productTypeFilter,
  ]);

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Typography variant="h4" style={{ marginTop: 8 }}>
        Delta trend heatmap <DocsDrawer path="/dashboards/delta.html" />
      </Typography>
      <Box
        style={{
          height: 61,
          display: "flex",
          alignItems: "end",
          justifyContent: "right",
        }}
      >
        <FormControl style={{ marginLeft: 8, width: 150 }}>
          <InputLabel>Region</InputLabel>
          <Select
            defaultValue={""}
            onChange={(e) =>
              setRegionFilter((e.target.value as string) || undefined)
            }
          >
            <MenuItem value="">
              <ListItemText primary="All" />
            </MenuItem>
            {sortedRegions.map((region, idx) => {
              return (
                <MenuItem key={`region${idx}`} value={region.id}>
                  <ListItemText primary={region.name} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl style={{ marginLeft: 8, width: 150 }}>
          <InputLabel>Cloud</InputLabel>
          <Select
            defaultValue={""}
            onChange={(e) =>
              setTeamTypeFilter((e.target.value as TeamType) || undefined)
            }
          >
            <MenuItem value="">
              <ListItemText primary="All" />
            </MenuItem>
            {teamTypes.map((type, idx) => {
              return (
                <MenuItem key={`type${idx}`} value={type}>
                  <ListItemText primary={type} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl style={{ marginLeft: 8, width: 150 }}>
          <InputLabel>Product</InputLabel>
          <Select
            defaultValue={""}
            onChange={(e) =>
              setProductTypeFilter((e.target.value as string) || undefined)
            }
          >
            <MenuItem value="">
              <ListItemText primary="All" />
            </MenuItem>
            {productTypes.map((type, idx) => {
              return (
                <MenuItem key={`type${idx}`} value={type}>
                  <ListItemText primary={type} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={showEngineers}
                onChange={() => setShowEngineers(!showEngineers)}
              />
            }
            label="Engineers"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={showCustomers}
                onChange={() => setShowCustomers(!showCustomers)}
              />
            }
            label="Customers"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={showOffboardings}
                onChange={() => setShowOffboardings(!showOffboardings)}
              />
            }
            label="Offboardings"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={showTimeOff}
                onChange={() => setShowTimeOff(!showTimeOff)}
              />
            }
            label="PTO"
          />
        </FormGroup>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Typography variant="caption" style={{ color: "gray" }}>
          {filteredTeams?.length || 0} / {sortedTeams?.length || 0} teams
        </Typography>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Team</TableCell>
            <TableCell>Region</TableCell>
            {dates.map((date, index) => {
              return (
                <TableCell key={`date${index}`} className={classes.cell}>
                  {date.toISOString().substring(0, 7)}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTeams
            .filter((team) => teams[team.id])
            .map((team, index) => {
              return (
                <TableRow key={`team${index}`}>
                  <TableCell>
                    <Link
                      to={`${location.pathname}/${team.id}`}
                      className={linkStyles.link}
                    >
                      {team.name}{" "}
                      {(team.type || []).map(
                        (type: string, typeIdx: number) => (
                          <Chip
                            key={`type${typeIdx}`}
                            label={type}
                            style={{ marginRight: 2 }}
                          />
                        )
                      )}
                    </Link>
                  </TableCell>

                  <TableCell>
                    {team.region ? (
                      <Link
                        to={`/dashboards/regions/${team.region}`}
                        className={linkStyles.link}
                      >
                        {regions[team.region]?.name}
                      </Link>
                    ) : null}
                  </TableCell>

                  {dates.map((date, index) => {
                    const snapshot =
                      snapshots[date.toISOString().substring(0, 7)][team.id];
                    const budget = snapshot?.getBudget();
                    const planned = budget?.getPlannedHours() || 0;
                    const capacity = snapshot?.getCapacity(nowDate) || 0;
                    const capacityLabel = snapshot?.getCapacityLabel(nowDate);
                    const delta = snapshot?.getDelta(nowDate) || 0;
                    const deltaPercent =
                      snapshot?.getDeltaPercent(nowDate) || 0;
                    const tooltip = `Planned hours ${Math.round(
                      planned
                    )}h / ${capacityLabel} ${Math.round(capacity)}h`;

                    //debugger

                    return (
                      <TableCell
                        key={`utilization${index}`}
                        style={{ background: mapValueToColor(delta) }}
                        className={classes.cell}
                      >
                        <Tooltip title={tooltip}>
                          <Link
                            to={`${location.pathname}/${team.id
                              }?year=${date.getFullYear()}&month=${date.getMonth() + 1
                              }`}
                            className={linkStyles.link}
                          >
                            <Box>
                              {Math.round(delta) || "-"}{" "}
                              <Typography variant="caption">
                                (
                                {isNaN(deltaPercent)
                                  ? ""
                                  : deltaPercent.toFixed(1)}
                                %)
                              </Typography>
                            </Box>
                            {showEngineers ? (
                              <Box>
                                {snapshot?.countEngineers() || 0} Engineers
                              </Box>
                            ) : null}
                            {showCustomers ? (
                              <Box>
                                {snapshot?.countCustomers() || 0} Customers
                              </Box>
                            ) : null}
                            {showOffboardings ? (
                              <Box>
                                {snapshot?.countCustomersOffboarded() || 0}{" "}
                                Offboardings
                              </Box>
                            ) : null}
                            {showTimeOff ? (
                              <Box>
                                {Math.round(snapshot?.getTimeoffHours() || 0)}h{" "}
                                PTO
                              </Box>
                            ) : null}
                          </Link>
                        </Tooltip>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Typography variant="caption" style={{ color: "gray" }}>
        Before {nowDate.toISOString().substring(0, 10)}: planned hours vs.
        logged hours in Harvest
      </Typography>
      <br />
      <Typography variant="caption" style={{ color: "gray" }}>
        From {nowDate.toISOString().substring(0, 10)}: planned hours vs. planned
        capacity
      </Typography>
    </Box>
  );
}
