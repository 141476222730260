import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import useCustomersIdsInAggregator from "customers/useCustomerIdsInAggregator";
import useCustomersInAggregator from "customers/useCustomersInAggregator";
import { CustomersBurnedBudgetScatterChartCard } from "dashboards/utilization/BurnedBudgetByCustomerScatterChartCard";
import useEngineersInAggregator from "engineers/useEngineersInAggregator";
import {
  CustomerAggregatorType,
  EngineerAggregatorType,
  Team
} from "msd-capacity-planning-model";
import { CustomersNotificationsCard } from "notifications/customer/CustomersNotificationCard";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CountCard } from "../../components/CountCard";
import { TeamsNotificationsCard } from "../../notifications/team/TeamsNotificationsCard";
import { BurnedBudgetByTeamScatterChartCard } from "../utilization/BurnedBudgetByTeamScatterChartCard";
import { BurnedBudgetCard } from "../utilization/BurnedBudgetCard";
import { UtilizationByTeamScatterChartCard } from "../utilization/UtilizationByTeamScatterChartCard";
import { UtilizationRatioCard } from "../utilization/UtilizationRatioCard";
import { TeamsTable } from "./TeamsTable";

export function TeamsDashboard({
  year,
  month,
  teams,
}: {
  year: number;
  month: number;
  teams: { [teamId: string]: Team };
}) {
  const location = useLocation();
  const history = useHistory();
  const [teamIds, setTeamIds] = useState([] as string[]);
  const customers = useCustomersInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamIds
  );
  const customerIds = useCustomersIdsInAggregator(
    year,
    month,
    CustomerAggregatorType.TEAM,
    teamIds
  );
  const engineers = useEngineersInAggregator(
    year,
    month,
    EngineerAggregatorType.TEAM,
    teamIds
  );

  useEffect(() => {
    setTeamIds(Object.keys(teams));
  }, [teams]);

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={9}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={6}>
              <BurnedBudgetCard
                year={year}
                month={month}
                customers={customers}
              />
            </Grid>
            <Grid item xs={6}>
              <UtilizationRatioCard
                year={year}
                month={month}
                customers={customers}
                engineers={engineers}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomersBurnedBudgetScatterChartCard
                year={year}
                month={month}
                customerIds={customerIds}
                title="Burned budget by customer"
              />
            </Grid>

            <Grid item xs={6}>
              <UtilizationByTeamScatterChartCard
                year={year}
                month={month}
                teams={teams}
                linkPrefix={location.pathname}
              />
            </Grid>
            <Grid item xs={6}>
              <BurnedBudgetByTeamScatterChartCard
                year={year}
                month={month}
                teams={teams}
                linkPrefix={location.pathname}
                title="Burned budget by team"
              />
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ marginTop: 32 }}>
                <TeamsTable 
                  year={year} 
                  month={month} 
                  teams={teams} 
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <CountCard title="Teams" count={Object.keys(teams).length} />
            </Grid>
            <Grid item xs={12}>
              <List>
                <ListItem
                  button
                  onClick={() => history.push(`${location.pathname}/delta`)}
                >
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delta trend" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => history.push(`${location.pathname}/hiring`)}
                >
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Hiring needs" />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <TeamsNotificationsCard year={year} month={month} teams={teams} />
              <CustomersNotificationsCard
                year={year}
                month={month}
                customerIds={customerIds}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
