import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Tooltip,
  useTheme,
  Select,
  MenuItem,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { NoStyleLink } from "components/NoStyleLink";
import { DashboardBreadcrumbs } from "dashboards/DashboardBreadcrumbs";
import { EMCustomersOffboarded } from "./EMCustomerOffboarded";
import { useDashboardPeriod } from "dashboards/useDashboardPeriod";
import { DocsDrawer } from "docs/DocsDrawer";
import { useEMsByLead } from "ems/useEMsByLead";
import {
  Team,
} from "msd-capacity-planning-model";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useRegions from "regions/useRegions";
import { useBudgetByAggregator, BudgetByGroup } from "../../customers/useBudgetByAggregator";
import { CustomerAggregatorType } from "msd-capacity-planning-model";
import { useSortedEMLeads } from "ems/useSortedEMLeads";
import { mapValueToColor } from "utils/colorGenerator";

function createDates(year: number, month: number): Date[] {
  return [
    new Date(year, month),
    new Date(year, month + 1),
    new Date(year, month + 2),
    new Date(year, month + 3),
    new Date(year, month + 4),
    new Date(year, month + 5),
    new Date(year, month + 6)
  ];
}

export function EMDeltaDashboardPage({
  teams = {},
  linkPrefix,
  routelinkPrefix
}: {
  teams: { [teamId: string]: Team };
  linkPrefix?: string;
  routelinkPrefix?: string
}) {
  const { year, month } = useDashboardPeriod();
  const [nowDate] = useState(new Date(year, month));
  const location = useLocation();
  const theme = useTheme();
  const leadEms = useEMsByLead();
  let recursiveEMs = useEMsByLead(true);
  recursiveEMs.all = recursiveEMs['']
  const emLeads = useSortedEMLeads(year, month);
  const regions = useRegions();
  const budgetObject: BudgetByGroup[] = [];
  const [dates, setDates] = useState([] as Date[]);
  let data: any = []
  const [showCustomers, setShowCustomers] = useState(false);
  const [showOffboardings, setShowOffboardings] = useState(false);
  const [showTimeOff, setShowTimeOff] = useState(false);
  const [selectedEMLead, setSelectedEMLead] = useState('all');
  let ems = selectedEMLead == 'all' ? recursiveEMs : leadEms
  let customerIds:any;
  let tmpDataValues: any = [];

  // individual date columns are instanciated here with options
  const dateCols: any = dates.map((date, index) => ({
    name: date.toISOString().substring(0, 7),
    options: {
      setCellProps: () => ({ style: { padding: 0 } }),
      setCellHeaderProps: () => ({ style: { padding: 0 } }),
      filter: false,
      sort: false
    }
  }))

  useEffect(() => {
    // calculate dates
    const dates = createDates(year, month);
    setDates(dates);
  }, [year, month]);


  budgetObject[0] = useBudgetByAggregator(dates[0]?.getFullYear(), dates[0]?.getMonth(), CustomerAggregatorType.EM);
  budgetObject[1] = useBudgetByAggregator(dates[1]?.getFullYear(), dates[1]?.getMonth(), CustomerAggregatorType.EM);
  budgetObject[2] = useBudgetByAggregator(dates[2]?.getFullYear(), dates[2]?.getMonth(), CustomerAggregatorType.EM);
  budgetObject[3] = useBudgetByAggregator(dates[3]?.getFullYear(), dates[3]?.getMonth(), CustomerAggregatorType.EM);
  budgetObject[4] = useBudgetByAggregator(dates[4]?.getFullYear(), dates[4]?.getMonth(), CustomerAggregatorType.EM);
  budgetObject[5] = useBudgetByAggregator(dates[5]?.getFullYear(), dates[5]?.getMonth(), CustomerAggregatorType.EM);
  budgetObject[6] = useBudgetByAggregator(dates[6]?.getFullYear(), dates[6]?.getMonth(), CustomerAggregatorType.EM);


  const capableHours = (em: any, year: any, month: any) => {
    const capacity = em
      .getCapacityHandle()
      .getCapacity(year, month, regions[em.region])
    const value = Math.round(capacity.countWorkingHours())
    return value
  }

  const getPTO = (em: any, year: any, month: any) => {
    const capacity = em
      .getCapacityHandle()
      .getCapacity(year, month, regions[em.region]);
    const value = capacity.countWeekDayTimeOff();
    return value
  }


  const regionNames = useMemo(
    () => {
      return Array.from(new Set(Object.values(ems[selectedEMLead] || []).map(em => regions[em?.region]?.name))).filter(n => n !== undefined)
    }, [ems]
  );

  let columns = [
    {
      name: "email",
      options:
      {
        download: false,
        filter: false,
        display: false
      }
    },
    {
      name: "EM",
      options: {
        setCellProps: () => ({ style: { "padding-left": 5, "padding-top": 0, "padding-bottom": 0, "padding-right": 0 } }),
        setCellHeaderProps: () => ({ style: { "padding-left": 5, "padding-top": 0, "padding-bottom": 0, "padding-right": 0 } }),
        filter: false,
        sort: false,
        sortCompare: (order: string) => {
          return (obj1: { data: { props: { text: string; }; }; }, obj2: { data: { props: { text: string; }; }; }) => {
            return obj1.data.props.text.localeCompare(obj2.data.props.text) * (order === 'asc' ? 1 : -1);
          };
        },
      }
    },
    {
      name: "Region",
      options: {
        setCellProps: () => ({ style: { padding: 0 } }),
        sort: false,
        filterOptions: {
          names: regionNames,
          logic(region: any, filters: any) {
            let textString = region.props.text;
            return !filters.includes(textString);
          }

        },
        setCellHeaderProps: () => ({ style: { padding: 0 } }),
        sortCompare: (order: string) => {
          return (obj1: { data: { props: { text: string; }; }; }, obj2: { data: { props: { text: string; }; }; }) => {
            return obj1.data.props.text.localeCompare(obj2.data.props.text) * (order === 'asc' ? 1 : -1);
          };
        },
      }
    }
  ]

  useMemo(() => {
    columns.push(...dateCols)
  }, [dates, columns])

  useMemo(() => {
    // for each EM, update data variable with the needed information=
    //for (const [key, value] of Object.entries(ems)) {

    for (let [key, value] of Object.entries(ems[selectedEMLead] || [])) {
      key = value['id']
      tmpDataValues = [];
      data.push(
        [
          value.id,                                                         // EM ID -- going to hide this
          <NoStyleLink
            pathname={`${linkPrefix}/${value.id}${location.search}`}
            text={value.firstName + " " + value.lastName}
          />,                                                          // EM name
          <NoStyleLink
            pathname={`${routelinkPrefix}/${regions[value.region]?.id}`}
            text={regions[value.region]?.name}
          />                                                           // Region
        ],

      )
      let indexOfEM: any = data.findIndex((object: string[]) => {
        return object[0] === key;
      })
      // for each month and year we do, get values for the EM we are working on.
      dateCols.map((date: any, index: any) => {
        let year = Number(dateCols[index]['name'].split("-")[0])
        let month = Number(dateCols[index]['name'].split("-")[1])
        let tmpCapableHours = capableHours(value, year, month - 1) || 0
        // doing an OR tmpCapableHours forces the display to show 0 if assignedHours has not loaded yet.
        let tmpassignedHours = Math.round(budgetObject[index][value.id]?.emHours) || tmpCapableHours
        let tmpAvailable = tmpCapableHours - tmpassignedHours || 0
        let tmpCustomerNumber = Object.keys(budgetObject[index][value.id]?.items || {}).length
        let tmpPTOHours = getPTO(value, year, month - 1)
        // set my values in advance so I can filter out all 0 value entries
        tmpDataValues.push(...[tmpAvailable, tmpCustomerNumber, tmpPTOHours])

        if (tmpAvailable != undefined || (showCustomers && tmpCustomerNumber != 0) || (showTimeOff && tmpPTOHours != 0)) {



           if (budgetObject[0][value.id] != undefined) {
            customerIds = Object.keys(budgetObject[0][value.id].items)
           }else{
            customerIds = false
           }

          const offboardings = <EMCustomersOffboarded year={year} month={month - 1} customerIds={customerIds}/>

          data[indexOfEM] = (data[indexOfEM] || []).concat([
            <Tooltip title={tmpCapableHours + " Capable minus " + tmpassignedHours + " Assigned "}>
              <Box sx={{ bgcolor: mapValueToColor(tmpAvailable) }}>
                <Box sx={{ textAlign: 'center', padding: 10 }}>{tmpAvailable || 0}</Box>
                {showCustomers ? (<Box sx={{ textAlign: 'right' }}>{tmpCustomerNumber} Customers</Box>) : null}
                {showOffboardings ? (<Box>{offboardings}</Box>) : null}
                {showTimeOff ? (<Box sx={{ textAlign: 'right' }}>{tmpPTOHours} PTO Days</Box>) : null}
              </Box>
            </Tooltip>
          ]
          )
        }
      })
      // the following removes any EMs that have been offboarded.
      if (value.isOffboarded()){
        data.splice(indexOfEM,1)
      }
    }

  }, [ems, dateCols, selectedEMLead])

  const handleEMLeadChange = (event: any) => {
    setSelectedEMLead(event.target.value);
  };

  return (
    <Box>
      <DashboardBreadcrumbs />
      <Typography variant="h4" style={{ marginTop: 8 }}>
        Delta trend heatmap <DocsDrawer path="/dashboards/emdelta.html" />
      </Typography>

      <Box
        style={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Select
                value={selectedEMLead}
                onChange={handleEMLeadChange}
                defaultValue='all'
              >
                <MenuItem value='all'>Show All</MenuItem>
                {Object.values(emLeads).map((emLead, index) => {
                  return (
                    <MenuItem value={emLead.id}>
                      {emLead.getDisplayName()}
                    </MenuItem>
                  )
                })}
              </Select>}
            label="Lead"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={showCustomers}
                onChange={() => setShowCustomers(!showCustomers)}
              />
            }
            label="Customers"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={showOffboardings}
                onChange={() => setShowOffboardings(!showOffboardings)}
              />
            }
            label="Offboardings"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={showTimeOff}
                onChange={() => setShowTimeOff(!showTimeOff)}
              />
            }
            label="PTO"
          />
        </FormGroup>
      </Box>
      <MUIDataTable
        title=""
        columns={columns}
        data={data}
        options={
          {
            pagination: false,
            print: false,
            onDownload: (buildHead, buildBody, columns, data) => {
              return "\uFEFF" + buildHead(columns) + buildBody(
                data.map((it: { index: any; data: any; }) => {
                  const response = {
                    index: it.index,
                    data: [
                      it.data[0],
                      it.data[1].props.text,   //set EM
                      it.data[2].props.text, //set region
                    ]
                  }

                  const dates = it.data.slice(3, it.data.length)
                  dates.forEach((date: any, indexDates: number) => {
                    const cellValues = date?.props?.children?.props?.children;
                    let cellValueString: string = "";
                    cellValues && cellValues.forEach((cellValue: any, index: number) => {
                      let cellValueStringPartial = "";

                      if (cellValue && (cellValue.props?.children !== null && typeof cellValue.props?.children != undefined)) {
                        const cellData = cellValue.props.children
                        if (cellData || cellData === 0) {
                          switch (index) {
                            case 0:
                              // convert negative numbers to (number)
                              cellValueStringPartial = JSON.stringify(cellData) || "0";
                              if (parseInt(cellValueStringPartial) < 0){
                                cellValueStringPartial = `(${Math.abs(parseInt(cellValueStringPartial))})`
                              }
                              break;
                            case 1:
                              //Customers
                              cellValueStringPartial = cellData[1] + " " + cellData[0];
                              break;
                            case 2:
                              //Offboardings
                              const column = indexDates+3
                              const row = it.index
                              const selector = `[id^="MUIDataTableBodyRow"][id$="${row}"] td:nth-child(${column}) div:nth-child(${index}) > div > div[class*="MuiBox-root"] > div`;
                              const tdElement = document.querySelector(selector);
                              cellValueStringPartial = tdElement.innerHTML.split(' ').reverse().join(' ');
                              break;
                            case 3:
                              //PTO Days
                              cellValueStringPartial = cellData[1] + " " + cellData[0];
                              break;
                          }
                          if (cellValueStringPartial.length > 0) {
                            if (index == 0) {
                              cellValueString = cellValueStringPartial
                            } else {
                              cellValueString = cellValueString + ", " + cellValueStringPartial
                            }
                          }
                        }
                      }
                    })
                    response.data.push(cellValueString)
                  })
                  return (response)
                })
              )
            },
            search: false,
            selectableRows: "none",
            viewColumns: false,
          }}
      />

      <Typography variant="caption" style={{ color: "gray" }}>
        Before {nowDate.toISOString().substring(0, 10)}: planned hours vs.
        logged hours in Harvest
      </Typography>
      <br />
      <Typography variant="caption" style={{ color: "gray" }}>
        From {nowDate.toISOString().substring(0, 10)}: planned hours vs. planned
        capacity
      </Typography>
    </Box>
  );
}
