import { Container } from "@material-ui/core";
import { TeamDashboardRoutePage } from "dashboards/team/TeamDashboardRoutePage";
import { TeamHandle } from "msd-capacity-planning-model";
import { Route, Switch } from "react-router-dom";
import { TeamsDeltaDashboardPage } from "./TeamsDeltaDashboardPage";
import { TeamsHiringNeeds } from "./TeamsHiringNeeds";
import { TeamsOverviewDashboardPage } from "./TeamsOverviewDashboardPage";
import { useDashboardPeriod } from "../useDashboardPeriod";

export function TeamsDashboardRoutePage({
  baseUrl,
  teams
}: {
  baseUrl: string;
  teams: { [teamId: string]: TeamHandle };

}) {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path={`${baseUrl}/delta`} exact={true}>
          <TeamsDeltaDashboardPage teams={teams} />
        </Route>
        <Route path={`${baseUrl}/hiring`} exact={true}>
          <TeamsHiringNeeds teams={teams} />
        </Route>
        <Route path={`${baseUrl}/delta/:team`}>
          <TeamDashboardRoutePage baseUrl={`${baseUrl}/delta`} />
        </Route>
        <Route path={`${baseUrl}/:team`}>
          <TeamDashboardRoutePage baseUrl={baseUrl} />
        </Route>
        <Route path={`${baseUrl}`}>
          <TeamsOverviewDashboardPage teams={teams} />
        </Route>
      </Switch>
    </Container>
  );
}
